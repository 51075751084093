export const MIN_WIDTH_IPAD10 = 834
export const MAX_WIDTH_IPAD10 = 1112
export const MIN_WIDTH_IPHONE5S = 320
export const MAX_WIDTH_IPHONE5S = 568
export const MIN_WIDTH_IPHONE_6_7_8 = 375
export const MAX_WIDTH_IPHONE_6_7_8 = 667
export const MIN_WIDTH_IPHONE_6_7_8_PLUS = 414
export const MAX_WIDTH_IPHONE_6_7_8_PLUS = 736
export const MIN_WIDTH_IPHONE_XS = 375
export const MAX_WIDTH_IPHONE_XS = 812
export const MIN_WIDTH_IPHONE_11_XR_XS_MAX = 414
export const MAX_WIDTH_IPHONE_11_XR_XS_MAX = 896
export const MIN_WIDTH_LAPTOP_AND_DESKTOP = 1280
export const MIN_WIDTH_IPAD = 768
export const MAX_WIDTH_IPAD = 1024
// local
// export const BACKEND_BASE_PATH = 'http://localhost:8085'

// Site key for google Captcha
export const SITE_KEY = '6LezTdEaAAAAAF8Piohj1G9Q-DJl_L3ZQef4Hwt6'
// production
export const BACKEND_BASE_PATH = 'https://cms.gdpventure.com'
export const BACKEND_BASE_IMG_PATH = 'https://cms.gdpventure.com'
export const JOBS_SITE_URL = 'https://jobs.gdpventure.com'
